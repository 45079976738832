import React, { useState, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';


import './IntroView.css';
import SocialIcons from '../../widgets/SocialIcons/SocialIcons';
import ScrollButtons from '../../widgets/ScrollButtons/ScrollButtons';

const IntroView = () => {
    const [showJobTitle, setShowJobTitle] = useState(false);
    const [isScrollable, setIsScrollable] = useState(false);

    useEffect(() => {
        if (!isScrollable) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isScrollable]);

    const handleAnimationComplete = () => {
        setShowJobTitle(true);
        setIsScrollable(true);
    };


    return (
        <div id="intro-view" className="section">
            <div className="centered">
                {showJobTitle && (
                    <SocialIcons />
                )}
                <div className="centered-text">
                    <TypeAnimation
                        sequence={[
                            'Welcome!',
                            1500,
                            'I am Josh',
                            500,
                            handleAnimationComplete
                        ]}
                        wrapper="span"
                        cursor={true}
                        style={{ fontSize: '3em', display: 'inline-block' }}
                    />
                    {showJobTitle && <div style={{ marginTop: '20px', marginBottom: '40px' }}>Software Engineer</div>}
                </div>
                {showJobTitle && <ScrollButtons />}
            </div>
        </div>
    );
}

export default IntroView;
