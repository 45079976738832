import IntroView from './components/views/IntroView/IntroView';

function App() {

  return (
    <div className="App">
      <div className="stars">
        <div className="twinkling">
          <div className="clouds">
            <IntroView />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
