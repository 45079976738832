import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa';

import './SocialIcons.css';

const SocialIcons = () => {
    const socialLinks = {
        github: "https://github.com/JoshCap20",
        instagram: "https://instagram.com/joshcaponigro",
        linkedin: "https://linkedin.com/in/jcaponigro"
    };

    return (
        <div className="social-icons">
            <a href={socialLinks.github} aria-label="Github" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
            <a href={socialLinks.linkedin} aria-label="Linkedin" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
            <a href={socialLinks.instagram} aria-label="Instagram" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
        </div>
    );
}

export default SocialIcons;