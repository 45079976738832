import Button from '@mui/material/Button';

import './ScrollButtons.css';

const ScrollButtons = () => {
    const openResumePdf = () => {
        // Replace 'resume.pdf' with the actual path to your PDF if necessary
        window.open('/JoshCaponigro_Resume.pdf', '_blank');
    }

    return (
        <div className="buttons">
            <Button
                onClick={openResumePdf}
                variant="outlined"
                size="large"
                sx={{
                    color: 'white',
                    borderColor: 'white',
                    '&:hover': {
                        borderColor: 'gray',
                        backgroundColor: '#3a3737',
                    }
                }}
            >
                Resume
            </Button>
        </div>
    );
}

export default ScrollButtons;